* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    height: 99%;
}

.index-container {
    background-image: url(https://tqpytokz.cdn.imgeng.in/media-adsa/static/3560/928.jpg);
    background-size: cover;
    background-position: center;

    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}